import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';

declare global {
  interface Window {
    fbAsyncInit: () => void;
    FB: any;
  }
}

@Injectable({
  providedIn: 'root'
})

export class FacebookSdkService {
  constructor() { }

  loadSdk(): Promise<void> {
    return new Promise((resolve) => {
      if (window.FB) {
        resolve();
        return;
      }

      window.fbAsyncInit = () => {
        window.FB.init({
          appId: environment.metaAppId,
          xfbml: true,
          cookie: true,
          version: 'v19.0'
        });
        resolve();
      };

      const scriptElement = document.createElement('script');
      scriptElement.id = 'facebook-jssdk';
      scriptElement.src = "https://connect.facebook.net/en_US/sdk.js";
      document.head.appendChild(scriptElement);
    });
  }

  async login(configId: string, instagram: boolean): Promise<any> {
    return new Promise((resolve, reject) => {
        window.FB.login(async (response: any) => {
            if (response.authResponse) {
                try {
                    // Immediately fetching account information after successful login
                    const accountsResponse = await new Promise((resolve, reject) => {
                        let params = {};
                        if(instagram) {
                          params = {
                            fields: 'id,name,picture,instagram_business_account,access_token'
                          }
                        }
                        window.FB.api(`${response.authResponse.userID}/accounts`, params, (response: any) => {
                            if (response && !response.error) {
                                resolve(response);
                            } else {
                                reject('Failed to fetch accounts data');
                            }
                        });
                    });

                    resolve({
                        loginResponse: response,
                        accountsData: accountsResponse
                    });
                } catch (error) {
                    console.error(error);
                    reject('Failed to fetch additional data after login.');
                }
            } else {
                reject('User canceled login or did not fully authorize.');
            }
        }, { config_id: configId });
    });
  }

  async loginPaid(configId: string, instagram: boolean): Promise<any> {
    return new Promise((resolve, reject) => {
        window.FB.login(async (response: any) => {
            if (response.authResponse) {
                try {
                    const businessResponse = await new Promise((resolve, reject) => {
                        let params = {};
                        window.FB.api(`${response.authResponse.userID}/businesses`, params, (response: any) => {
                            if (response && !response.error) {
                                resolve(response);
                            } else {
                                reject('Failed to fetch accounts data');
                            }
                        });
                    });

                    resolve({
                        loginResponse: response,
                        businessData: businessResponse
                    });
                } catch (error) {
                    console.error(error);
                    reject('Failed to fetch additional data after login.');
                }
            } else {
                reject('User canceled login or did not fully authorize.');
            }
        }, { config_id: configId });
    });
  }

  adAccounts(businessId: string): Promise<any> {
    return new Promise((resolve, reject) => {
        if (!window.FB) {
            reject('Facebook SDK not loaded.');
            return;
        }

        let params = {
            fields: 'id,name'
        };

        const ownedAccountsPromise = new Promise<any>((resolve, reject) => {
            window.FB.api(`${businessId}/owned_ad_accounts`, params, (response: any) => {
                if (response && !response.error) {
                    resolve(response.data);
                } else {
                    reject('Failed to fetch owned ad accounts data');
                }
            });
        });

        const clientAccountsPromise = new Promise<any>((resolve, reject) => {
            window.FB.api(`${businessId}/client_ad_accounts`, params, (response: any) => {
                if (response && !response.error) {
                    resolve(response.data);
                } else {
                    reject('Failed to fetch client ad accounts data');
                }
            });
        });


        Promise.all([ownedAccountsPromise, clientAccountsPromise])
            .then((responses) => {
                const combinedData = [...responses[0], ...responses[1]].filter((value, index, self) =>
                    index === self.findIndex((item) => item.id === value.id)
                );

                resolve({ data: combinedData });
            })
            .catch((error) => {
                reject(error);
            });
    });
}

  logout(): Promise<void> {
    return new Promise((resolve, reject) => {
      if (!window.FB) {
        reject('Facebook SDK not loaded.');
        return;
      }
      window.FB.logout((response: any) => {
        console.log('Logged out', response);
        resolve();
      });
    });
  }
}

